import React from 'react';
import ErrorNotFound from '@pedidosya/web-fenix/pages/Error';
import useIsWebView from '@app/shared/hooks/useIsWebView';
import { getWindow } from '@app/shared/utils/window';
import { useLinkContext } from '@app/shared/contexts/LinkProvider';
import { ACTIONS } from '@app/shared/utils/deeplink';

export const NotFound = () => {
  const { redirect } = useLinkContext();
  const isWebView = useIsWebView();

  const onClick = () => {
    const wn = getWindow();
    if (isWebView) {
      return redirect(ACTIONS.HOME);
    }
    if (wn) {
      wn.location.href = '/';
    }
  };
  return <ErrorNotFound errorCode={404} onClick={onClick} />;
};
